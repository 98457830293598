export function formControl() {
    const $fc = $(".form-control");

    if(!$fc.length) return;

    $fc.blur(function() {
        const $t = $(this);

        if ($t.next('.label').length) {
            if ($t.val()) {
                $t.next('.label').addClass("filled");
            } else {
                $t.next('.label').removeClass("filled");
            }
        }
    });
}

import 'appear/dist/appear.min';
import 'jquery-countto';

export function initCounters() {

    const $c = $('.counter-item');

    if(!$c.length) return;

    appear({
        elements: function elements() {
            return document.getElementsByClassName('counter-block');
        },
        appear: function appear(el) {
            $(el).find('.counter-item span').countTo()
        },
        bounds: -50,
        reappear: false
    });
}

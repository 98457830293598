export function initVideo() {
    const $v = $('.video-wrapper');
    const $cw = $('.cover-wrapper');

    if(!$v.length) return;

    $cw.on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $(this).fadeOut(300);
        const youtubeVideo = $(this).prev('.youtube-video')[0];
        const rutubeVideo = $(this).prev('.rutube-video')[0];
        if (youtubeVideo) {
            youtubeVideo.contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
        }
        if (rutubeVideo) {
            rutubeVideo.contentWindow.postMessage(JSON.stringify({
                type: 'player:play',
                data: {},
            }), '*');
        }
    });
}
